<template>
    <div class="new-header">
        <div class="row border-bottom border-white align-items-center h-100">
            <div
                class="col-md-4 col-lg-3 col-xl-2 text-center bg-black h-100  flex-column align-items-center justify-content-center d-none d-md-flex">
                <img src="@/assets/images/ecart-logo.png" alt="" class=" img-w-120 p-2 ">
            </div>
            <div class="col-12 col-md-8 col-lg-9 col-xl-10 ">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-auto d-md-none">
                            <nav class="navbar navbar-expand-md navbar-light px-0">
                                <button class="navbar-toggler mr-3" type="button"
                                    @click="showFloatMenu = !showFloatMenu" id="btn-collapse-menu">
                                    <transition name="custom-classes-transition" mode="out-in"
                                        enter-active-class="animated jello">
                                        <i class=" fa fa-bars" v-if="!showFloatMenu" key="open"></i>
                                        <i class=" fa fa-times" v-if="showFloatMenu" key="close"></i>
                                    </transition>
                                </button>
                                <img src="@/assets/images/logo-ecart.svg" alt=""
                                    class=" navbar-brand img-fluid img-70 mb-1 d-none d-md-block">
                                <img src="@/assets/images/ecart-logo.png" alt=""
                                    class=" navbar-brand img-fluid img-70 mb-1 d-md-none ">
                            </nav>
                        </div>
                        <div class="col-auto col-md-auto d-none d-md-block">
                            <h5 class="f-24 m-0 text-capitalize " >
                                <strong v-text=" pageName " id="dashboardTItle"></strong>
                            </h5>
                        </div>
                        <div class="col">
                            <div class="row align-items-center justify-content-end">
                                <div class="col-auto">
                                    <button class="btn btn-notifications mt-2 " id="btn-notifications"
                                        @click="changeNotifications(!showNotifications)">
                                        <i class=" far fa-bell fa-2x "></i>
                                        <div class="counter"
                                            v-if="pendingNotificationsCount && (pendingNotificationsCount > 0) ">

                                            <span class="badge badge-sm badge-pill badge-danger ">
                                                <strong class="f-w-600 d-none d-md-block"
                                                    v-text="pendingNotificationsCount > 9 ? '9+' : pendingNotificationsCount"></strong>
                                                <small class="d-md-none">
                                                    <strong class="f-w-600"
                                                        v-text="pendingNotificationsCount > 9 ? '9+' : pendingNotificationsCount"></strong>
                                                </small>
                                            </span>
                                        </div>
                                    </button>
                                </div>
                                <div class="col-auto d-none d-md-block">
                                    <div class="text-left ">
                                        <p class=" text-truncate mb-0  text-uppercase">
                                            <strong v-text=" 'Seller id: ' "></strong>
                                            <strong class="text-success" v-text=" user.seller_id "> </strong>
                                        </p>

                                        <p class=" text-truncate mb-0 ">
                                            <strong
                                                v-text="  (user.email && user.email.length > 18) ? (user.email.slice(0,18) + '...' ) : user.email "></strong>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-auto d-none d-md-block">

                                    <b-dropdown variant="none" size="sm" right no-caret>
                                        <template v-slot:button-content class="m-0">
                                            <button
                                                class="btn btn-success justify-content-center rounded-circle img-cover-60 border bg-white d-none d-md-flex"
                                                type="button">
                                                <h6 class=" m-auto text-uppercase f-24">
                                                    <strong
                                                        v-text="( (user.first_name) ? user.first_name[0] : '') + ( (user.last_name) ? user.last_name[0] : '' )  ">
                                                    </strong>
                                                </h6>
                                            </button>
                                            <button
                                                class="btn btn-success d-flex justify-content-center rounded-circle img-cover-40 border bg-white d-md-none"
                                                type="button">
                                                <h6 class=" m-auto text-uppercase ">
                                                    <strong
                                                        v-text="( (user.first_name) ? user.first_name[0] : '') + ( (user.last_name) ? user.last_name[0] : '' )  ">
                                                    </strong>
                                                </h6>
                                            </button>
                                        </template>
                                        <b-dropdown-item to="/dashboard/account">
                                            <div class="row align-items-center ">
                                                <div class="col-auto text-center pr-0 ">
                                                    <i class="fa fa-cog "></i>
                                                </div>
                                                <div class="col pl-2">
                                                    <span v-text=" $t('general.settings') "></span>
                                                </div>
                                            </div>
                                        </b-dropdown-item>
                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-item to="/logout">
                                            <div class="row align-items-center ">
                                                <div class="col-auto text-center pr-0">
                                                    <i class="fa fa-sign-out-alt  "></i>
                                                </div>
                                                <div class="col pl-2">
                                                    <span v-text=" $t('general.logout') "></span>
                                                </div>
                                            </div>
                                        </b-dropdown-item>
                                    </b-dropdown>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="notification-content" id="notifications">
            <div class="notification-container bg-white text-primary border-left "
                :class=" showNotifications ? 'notification-container-show' : 'notification-container-hiden' ">
                <div class="border-bottom p-1 " @click="changeNotifications(false)">
                    <router-link to="/dashboard/notifications" class="btn btn-sm w-100 text-left">
                        <div class="row justify-content-between align-items-center ">
                            <div class="col-auto text-center pr-0">
                                <i class="fa fa-external-link-alt "></i>
                            </div>
                            <div class="col pl-2">
                                <h5 class="m-0 f-w-600 f-16" v-text="$t('dashboard.notifications.title')"></h5>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="notification-container-list">
                    <div v-show="pendingNotificationsList.length > 0 ">
                        <table class="table">
                            <tr v-for=" (notification,index) in pendingNotificationsList" :key="index">
                                <td>
                                    <Notification v-bind:notification="notification" sm />
                                </td>
                            </tr>
                        </table>

                    </div>
                    <no-data v-show=" pendingNotificationsList.length == 0 "
                        :dataMessage="$t('noDataMessages.notificationsList')" class="h-100" sm :showBtn="false">
                    </no-data>
                </div>
            </div>
        </div>

        <transition name="custom" enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">

            <div class="float-menu-mobile d-md-none" v-if="showFloatMenu">
                <div class="menu-content d-flex flex-column justify-content-between p-4">
                    <div class="row">
                        <div class="col-12">
                            <router-link to="/dashboard/account" exact>
                            <div class="row align-items-center">
                                <div class="col-auto">
                                    <div class="circle-50 bg-success">
                                        <i class="fa fa-cog fa-lg"></i>
                                    </div>
                                </div>
                                <div class="col-8 pl-0 f-w-700">
                                    <p class="m-0">
                                        <span class="text-success" v-text="$t('tables.user') + ': '"></span> 
                                        <span v-text="user.seller_id"></span>
                                        </p>
                                    <p class="m-0 " v-text="user.email"></p>
                                </div>
                            </div>
                            </router-link>
                            
                            <hr class="my-4">
                        </div>
                        <div class="col-6 col-sm-4 mb-2" v-for=" (item,index ) in menuList " :key=" index ">
                            <router-link class="card rounded bg-white " :to="item.path">
                                <div class="card-body">
                                    <div class="d-flex flex-column justify-content-center align-items-center">
                                        <div class=" my-2">
                                            <i class="fa fa-lg " :class=" 'fa-' + item.icon "></i>
                                        </div>
                                        <p class="text-center f-w-500">
                                            <span class="text-left mr-2 "
                                                v-text=" $t('dashboard.menu.' + item.title ) "></span>
                                            <span class=" " v-if="item.title == 'orders' && ordersPendingCount ">
                                                <strong class="f-w-600 badge badge-pill badge-warning"
                                                    v-text="ordersPendingCount > 9 ? '9+' : ordersPendingCount"></strong>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <hr class="my-4">
                        </div>
                        <div class="col-12 " v-for=" (link,index) in infoLinks" :key="index">
                            <router-link class="text-white" :to="link.link" :target=' link.target '
                                v-if=" link.target == '_self'  ">
                                <div class="row">
                                    <div class="col">
                                        <p v-text="$t('components.footer.links.' + link.title)"></p>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fa fa-chevron-right"></i>
                                    </div>
                                </div>
                            </router-link>
                            <a :href="link.link" :target=' link.target ' v-else>
                                <div class="row">
                                    <div class="col">
                                        <p v-text="$t('components.footer.links.' + link.title)"></p>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fa fa-chevron-right"></i>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-12">
                            <router-link to="/logout">
                                <div class="row">
                                    <div class="col">
                                        <p v-text=" $t('general.logout') "></p>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fa fa-sign-out-alt  "></i>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

        </transition>


    </div>
</template>

<script>
    import {
        mapState,
        mapMutations,
        mapActions,
    } from "vuex";
    import Notification from "@/components/Notification";
    import {
        EcInfoLinkis
    } from "@/plugins/beans.js";
    export default {
        components: {
            Notification
        },
        data() {
            return {
                showFloatMenu: false,
                infoLinks: EcInfoLinkis(this),
            }
        },
        computed: {
            ...mapState('EcartNotifications', ['pendingNotificationsList', 'showNotifications',
                'pendingNotificationsCount'
            ]),
            ...mapState('EcartProducts', ['productsList']),
            ...mapState({
                ordersPendingCount: state => state.ordersPendingCount,
                menuList: state => state.menuList,
                user: state => state.session.user,
            }),

            pageName() {
                return this.$te('general.' + this.$route.name) ? this.$t('general.' + this.$route.name) : this.$route
                    .name;
            }
        },
        watch: {
            async pendingNotificationsList() {
                let tempList = [];
                this.pendingNotificationsList.map(notificaiton => {
                    if (notificaiton.event_type == 'question') {
                        tempList.push(notificaiton.reference_id);
                    }
                })
                await this.fnApiGetProductsInformation(tempList);
                this.fnSetProducts();
            },
            productsList() {
                this.fnSetProducts();
            }
        },
        methods: {
            ...mapMutations('EcartNotifications', ['changeNotifications']),
            ...mapActions('EcartProducts', ['fnApiGetProductsInformation']),
            fnSetProducts() {
                this.pendingNotificationsList.map(notificaiton => {
                    if ((notificaiton.product == null) || (notificaiton.product == undefined)) {
                        let tempProduct = this.productsList.find(product => (product.id == notificaiton
                            .reference_id));
                        if (tempProduct != undefined) {
                            notificaiton.product = tempProduct;
                        }
                    }
                })
            }
        },
        mounted() {
            window.addEventListener('click', (e) => {
                if (document.getElementById('notifications') && !document.getElementById('btn-notifications')
                    .contains(e.target) && !document.getElementById('notifications')
                    .contains(e.target)) {
                    this.changeNotifications(false)
                }
                if (document.getElementById('btn-collapse-menu') && !document.getElementById(
                        'btn-collapse-menu').contains(e.target)) {
                    this.showFloatMenu = false;
                }
            });
        },
    }
</script>

<style lang="scss">
    .new-header {
        height: 75px;
        position: sticky;
        top: 0px;
        z-index: 200;
        background-color: white;
        box-shadow: 0 0.25rem 0.75rem rgba($color: #000000, $alpha: .15) !important;
    }

    @media only screen and (max-width: 767px) {
        .new-header {
            height: 75px;
            background-color: black;
        }

        .navbar-toggler {
            color: white !important;
        }
    }

    .btn-notifications {
        position: relative;

        .counter {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: absolute;
            top: -5px;
            right: 0px;
            border-radius: 50%;
        }

        @media only screen and (max-width: 767px) {
            color: white;
        }

    }
</style>