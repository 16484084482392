<template>
  <div :class=" notification.read ? 'item-offline' : '' ">
    <div class="" @click="fnApiReadNotification(notification.id ) ;changeNotifications(false)">
      <router-link class="row align-items-center" v-if=" notification.event_type == 'question' "
        :to="  notification.original_reference_id ? ('/dashboard/questions/' +  notification.original_reference_id) : '/dashboard/questions' ">
        <div class="text-center" :class=" sm ? 'col-3 col-sm-2' : 'col-3 col-sm-2 col-lg-1 ' ">
          <div v-if=" notification.product != undefined &&  notification.product != null">
            <img v-image=" notification.product.imageUrl" class="img-contain-40 border bg-white ">
          </div>
          <div v-else>
            <img src="@/assets/images/no-image.jpg" class="img-cover-40 border " alt="">
          </div>
        </div>
        <div class="pl-0" :class=" sm ? 'col-9 col-sm-10 ' : 'col-9 col-sm-10 col-lg-11 ' ">
          <p class="m-0">
            <strong v-text=" $t('notifications.newQuestion') + '!!' "></strong>
            <small v-text=" ' - ' + fnGlobalGetTime(  notification.created_at ) "></small>
          </p>
          <p class=" m-0 " 
            :class="( notification.product && notification.product.name == 'notFound') ? 'text-warning' : 'text-muted'  ">
            <small class="f-w-500"
              v-text="  notification.product ? ( notification.product.name != 'notFound' ? notification.product.name : $t('messages.productNotFound') ) : ( $t('tables.loading') )"></small>
          </p>
        </div>
      </router-link>
      <router-link class="row align-items-center " v-if=" notification.event_type == 'review' "
        :to=" notification.original_reference_id ? ('/dashboard/reviews/' +  notification.original_reference_id) : '/dashboard/questions' ">
        <div class="text-center" :class=" sm ? 'col-3 col-sm-2' : 'col-3 col-sm-2 col-lg-1 ' ">
          <div v-if=" notification.product != undefined &&  notification.product != null">
            <img v-image=" notification.product.imageUrl" class="img-contain-40 border bg-white ">
          </div>
          <div v-else>
            <img src="@/assets/images/no-image.jpg" class="img-cover-40 border " alt="">
          </div>
        </div>
        <div class="pl-0" :class=" sm ? 'col-9 col-sm-10 ' : 'col-9 col-sm-10 col-lg-11 ' ">
          <p class="m-0">
            <strong v-text=" $t('notifications.newReview') + '!!' "></strong>
            <small v-text=" ' - ' + fnGlobalGetTime(  notification.created_at ) "></small>
          </p>
          <!-- <p class="m-0 text-success" v-html=" fnGetStars( 5 ) "> </p> -->
          <p class=" m-0"
            :class="( notification.product && notification.product.name == 'notFound') ? 'text-warning' : 'text-muted' ">
            <small class="f-w-500"
              v-text="  notification.product ? ( notification.product.name != 'notFound' ? notification.product.name : $t('messages.productNotFound') ) : ( $t('tables.loading') )"></small>
          </p>
        </div>
      </router-link>

      <router-link class="row align-items-center " v-if=" notification.module == 'orders' "
        :to="'/dashboard/orders/' +  notification.reference_id ">

        <div class="text-center" :class=" sm ? 'col-3 col-sm-2' : 'col-3 col-sm-2 col-lg-1 ' ">
          <i class="fa fa-file-invoice fa-2x"></i>
        </div>
        <div class="pl-0" :class=" sm ? 'col-9 col-sm-10 ' : 'col-9 col-sm-10 col-lg-11 ' ">
          <p class="m-0">
            <strong v-text=" $t('dashboard.orders.orderStatus')"></strong>
            <small v-text=" ' - ' + fnGlobalGetTime(  notification.created_at ) "></small>
          </p>
          <p class=" m-0"
            :class="( notification.product && notification.product.name == 'notFound') ? 'text-warning' : 'text-muted' ">
            <small class="f-w-500" v-text="  $t('messages.congratulationsYouhaveNewOrder') "
              v-if=" notification.event_type == 'pending' "></small>
            <small class="f-w-600 " v-text=" $t('messages.orderDelivered')" v-if=" notification.event_type == 'delivered' "></small>
          </p>
        </div>
      </router-link>


    </div>
  </div>
</template>


<script>
  import {
    mapActions,
    mapMutations,
    mapState
  } from 'vuex'
  export default {
    props: {
      notification: {
        required: true,
      },
      sm:{
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {}
    },
    computed: {
      ...mapState('EcartProducts', ['productsList']),
    },
    methods: {
      ...mapMutations('EcartNotifications', ['changeNotifications']),
      ...mapActions('EcartNotifications', ['fnApiReadNotification']),
      fnFindProduct(productId) {
        return this.productsList.find(product => (product.id == productId));
      }
    },
  }
</script>

<style>

</style>